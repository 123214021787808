import Enumerable from "linq";
import weatherUtil from "../util/weatherUtil";
export default class backendMethod {
    private static backAddr = `${process.env.NODE_ENV == "development" ? "http://localhost:3828" : ""}/backend/`;

    public static GetDataFromSZMB(api: string, query: string) {
        return fetch(this.backAddr + "GetDataFromSZMB/" + api + " / " + query).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: any[]) => {
                    return j;
                });
            else return null;
        });
    }
    public static getSZObtMind(ddatetime: Date) {
        return fetch(this.backAddr + "getSZObtMind/" + ddatetime.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: iLOCALOBTMIND[]) => {
                    if (j.length) {
                        for (var m of j) {
                            m.DDATETIME = new Date(m.DDATETIME);
                            m.WD3SMAXLEVEL = weatherUtil.WindLevel(m.WD3SMAXDF);
                        }
                    }
                    return j;
                });
            else return null;
        });
    }
    public static getSZObtHourd(obtId: String, start: Date, end: Date) {
        return fetch(this.backAddr + "getSZObtHourd/" + obtId + "/" + start.getTime() + "/" + end.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: iLOCALOBTHOURD[]) => {
                    if (j.length) {
                        for (var m of j) {
                            m.DDATETIME = new Date(m.DDATETIME);
                        }
                    }
                    return j;
                }); 
            else return null;
        });
    }
    public static getSZObtHourds(start: Date, end: Date) {
        return fetch(this.backAddr + "getSZObtHourds/" + start.getTime() + "/" + end.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: iLOCALOBTHOURD[]) => {
                    if (j.length) {
                        for (var m of j) {
                            m.DDATETIME = new Date(m.DDATETIME);
                        }
                    }
                    return j;
                }); 
            else return null;
        });
    }
    public static getSZObtHourds1(area: string, start: Date, end: Date) {
        return fetch(this.backAddr + "getSZObtHourds/" + area +"/" + start.getTime() + "/" + end.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: iLOCALOBTHOURD[]) => {
                    if (j.length) {
                        for (var m of j) {
                            m.DDATETIME = new Date(m.DDATETIME);
                        }
                    }
                    return j;
                });
            else return null;
        });
    }
    public static getObtDayDataByD30(obtId: String, end: Date) {
        return fetch(this.backAddr + "getObtDayDataByD30/" + obtId + "/" + end.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: number[]) => {                    
                    return j;
                });
            else return null;
        });
    }
    public static getObtDayDataByD30s(end: Date) {
        return fetch(this.backAddr + "getObtDayDataByD30/" + end.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: number[]) => {
                    return j;
                });
            else return null;
        });
    }

    public static getSZWarnnig(): Promise<iSZWarning[]> {
        return fetch(this.backAddr + "getSZWarnnig").then(resp => {
            if (resp.status == 200)
                return resp.json().then((j: iSZWarning[]) => {
                    if (j.length) {
                        let type2name: { [x: string]: string } = {
                            寒冷: "cold", 风暴潮: "fengbaochao", 大雾: "fog", 冰雹: "hail", 海浪: "hailang", 灰霾: "haze",
                            高温: "heat", 山体滑坡: "landslide", 强季风: "monsoon", 暴雨: "rain", 雷电: "light", 雷雨大风: "thunder-gust",
                            台风: "typhoon", 大风: "wind", 森林火险: "wildfire"
                        };
                        let color2name: { [x: string]: string } = { 红色: "red", 橙色: "orange", 黄色: "yellow", 蓝色: "blue", 白色: "white" };
                        for (var s of j)
                            s.PicUrl = `/images/signal/${type2name[s.Type]}_${color2name[s.Color]}.png`;
                    }
                    return j;
                });
            else return null;
        });
    }
    public static getGradientTower(startDate: Date, endDate: Date): Promise<iGradientTower[]> {
        return fetch(this.backAddr + "getGradientTower/" + startDate.getTime() + "/" + endDate.getTime()).then(resp => {
            if (resp.status == 200)
                return resp.json().then((t: iGradientTower[]) => {
                    for (var i of t) {
                        i.LAYERS = +i.LAYERS;
                        i.DDATETIME = new Date(i.DDATETIME);
                        i.TEMPERATURE /= 10;
                        i.HUMIDITY /= 10;
                        i.ALBEDO_AVG /= 10000;
                        i.WIND_SPEED /= 100;
                        i.WIND_SPEED_LEVEL = weatherUtil.WindLevel(i.WIND_SPEED);
                        i.WIND_DIRECTION /= 10;
                        i.WIND_DIRECT_NAME = isNaN(i.WIND_DIRECTION) ? "" : weatherUtil.WindDirectName(i.WIND_DIRECTION);
                        i.SOIL_TEM /= 100;
                    }
                    return t;
                });
            else return null;
        });
    }
    public static getNeighborhoodStation(lng: number, lat: number) {
        return fetch(this.backAddr + "getNeighborhoodStation/" + lng + "/" + lat).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static getSZObtMindPic(field: string, ddatetime: Date, isShenzhen: boolean) {
        //return this.backAddr + "getSZObtMindPic/" + field + "/" + ddatetime.getTime() + "/" + isShenzhen;
        var uri = this.backAddr + "getSZObtMindPic/" + field + "/" + ddatetime.getTime() + "/" + isShenzhen;
        return fetch(uri).then(resp => {
            if (resp.status == 200)
                return true;
            else return false;
        });
    }
    public static getShenzhenWMS(sizex: number, sizey: number, west: number, south: number, east: number, north: number, color: string="") {
        //return this.backAddr + "getShenzhenWMS/" + sizex + "/" + sizey + "/" + west + "/" + south + "/" + east + "/" + north;
        //let host = process.env.NODE_ENV == "development" ? "http://10.153.97.158:8080" : "http://10.153.97.158:8080";
        //return `${host}/geoserver/gbamwf/wms?&service=WMS&request=GetMap&version=1.1.1&layers=gbamwf%3Ashenzhen-map-group&styles=&format=image%2Fpng&transparent=true&width=${sizex}&height=${sizey}&srs=EPSG%3A4326&bbox=${west}%2C${south}%2C${east}%2C${north}`
        //return `http://10.153.98.46:18086/geoserver/Shenzhen/wms?service=WMS&version=1.1.0&request=GetMap&layers=Shenzhen:sz_warning&bbox=${west}%2C${south}%2C${east}%2C${north}&width=${sizex}&height=${sizey}&srs=EPSG:4326&format=image%2Fpng&transparent=true`
        var server = '10.153.98.46', port=18860
        var uri = `http://${server}:${port}/geoserver/sz_base/wms?service=WMS&version=1.1.0&request=GetMap&layers=sz_base:LFS_MeticulousFore&bbox=${west}%2C${south}%2C${east}%2C${north}&width=${sizex}&height=${sizey}&srs=EPSG:4326&format=image%2Fpng&transparent=true`
        if (color != "")
            uri += "&env=color:%23" + color
        return uri;
    }
    public static getFeedForecastPicUrl(field: string, startdate: Date, forhours: number, isShenzhen: boolean) {
        var uri = this.backAddr + "getFeedForecastPic/" + field + "/" + startdate.getTime() + "/" + forhours + "/" + isShenzhen;
        return fetch(uri).then(resp => {
            if (resp.status == 200)
                return true;
            else return false;
        });
    }
    public static getFeedForecastValue(field: string, longitude: number, latitude: number, issuedate: Date, startHours: number, maxHours: number, stepHours: number): Promise<number[]> {
        return fetch(this.backAddr + `getFeedForecastValue/${field}/${longitude}/${latitude}/${issuedate.getTime()}/${startHours}/${maxHours}/${stepHours}`).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static getShenzhenOBTCODE(): Promise<iOBTCODE[]> {
        return fetch(this.backAddr + "getShenzhenOBTCODE").then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static saveArticle(id: string, classify: string, title: string, icon: string, content: string, html: string, ddatetime: string): Promise<boolean> {
        return fetch(this.backAddr + "saveArticle", {
            method: "POST", mode: 'cors',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ id, classify, title, icon, content, html, ddatetime })
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
            else return null;
        });
    }
    public static getAllArticleWithoutContent(): Promise<iAgroArticle[]> {
        return fetch(this.backAddr + "getAllArticleWithoutContent").then(resp => {
            if (resp.status == 200) {
                return resp.json().then((result: iAgroArticle[]) => {
                    for (var r of result) 
                        r.creatime = new Date(r.creatime.toString());
                    return result;
                });
            }
            else return null;
        });
    }
    public static delArticle(id: string): Promise<number> {
        return fetch(this.backAddr + "delArticle/" + id).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static getArticle(id: string): Promise<iAgroArticle> {
        return fetch(this.backAddr + "GetArticleById/" + id).then(resp => {
            if (resp.status == 200)
                return resp.json().then((r: iAgroArticle) => {
                    r.creatime = new Date(r.creatime.toString());
                    r.content = JSON.parse(r.content)
                    return r;
                });
            else return null;
        });
    }
    public static getLastWeekObtDataByD(): Promise<iLOCALOBTDAYD[]> {
        return fetch(this.backAddr + "getLastWeekObtDataByD").then(resp => {
            if (resp.status == 200)
                return resp.json().then((r: iLOCALOBTDAYD[]) => {
                    for (var i of r) {
                        i.DDATETIME = new Date(i.DDATETIME.toString());
                        i.MAXTTIME = new Date(i.MAXTTIME.toString());
                        i.MINTTIME = new Date(i.MINTTIME.toString());
                        i.WD3SMAXTIME = new Date(i.WD3SMAXTIME.toString());
                    }
                    return r;
                });
            else return null;
        });
    }
    public static getStreetWarningInfo(issuedate: Date): Promise<iShenzhenStreet[]> {
        return fetch(this.backAddr + `getStreetWarningInfo/${issuedate.getTime()}`).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static classify2name(classify: string) {
        return ["深农有品", "农气资讯", "农普天地"][+classify - 1];
    }
    public static areaId2name(areaId: string) {
        let areaName = ""
        switch (areaId) {
            case "5949301": areaName = "罗湖区"; break;
            case "5949302": areaName = "福田区"; break;
            case "5949303": areaName = "南山区"; break;
            case "5949304": areaName = "盐田区"; break;
            case "5949305": areaName = "龙岗区"; break;
            case "5949306": areaName = "宝安区"; break;
            case "5949307": areaName = "光明区"; break;
            case "5949308": areaName = "坪山区"; break;
            case "5949310": areaName = "龙华区"; break;
            case "5949311": areaName = "大鹏新区"; break;
            case "5949312": areaName = "深汕合作区"; break;
            default: break
        }
        return areaName
    }


    public static NewsClassify2Name(classify: string) {
        let name = ""//
        switch (classify) {
            case "ClimateMonitorPredict": name = "气候监测预测"; break;
            case "ClimateImpactAssessment": 
            case "ClimateMonitorPredict_ClimateImpactAssessment": name = "气候影响评价"; break;
            case "ClimateReport": 
            case "ClimateMonitorPredict_ClimateReport": name = "气候公报"; break;
            case "AgroInfoForecast": name = "农气情报预报"; break;
            case "DisasterWarning": name = "灾害预警评估"; break;
            case "AgroWeatherForecast": name = "农用天气预报"; break;
            case "KeyAgricultural": name = "关键农事服务"; break;
            case "DroughtMonitor": name = "农气干旱监测"; break;
            default:
                break;
        }
        return name;
    }
    public static GetSimpleNews(classify: string, state: number = 1): Promise<iNewsArticle[]> {
        return fetch(this.backAddr + `GetSimpleNews/${classify}/${state}`).then(resp => {
            if (resp.status == 200) {
                return resp.json().then((result: iNewsArticle[]) => {
                    for (var r of result)
                        r.pubtime = new Date(r.pubtime.toString());
                    return result;
                });
            }
            else return null;
        });
    }

    public static GetNewsById(classify: string, id: string): Promise<iNewsArticle> {
        return fetch(this.backAddr + `GetNewsById/${classify}/${id}`).then(resp => {
            if (resp.status == 200) {
                return resp.json().then((result: iNewsArticle) => {
                    result.pubtime = new Date(result.pubtime.toString());
                    return result;
                });
            }
            else return null;
        });
    }
    public static ChangeState(id: string, state: number): Promise<number> {
        return fetch(this.backAddr + `ChangeState/${id}/${state}`).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static DelNews(id: string): Promise<number> {
        return fetch(this.backAddr + "DelNews/" + id).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }
    public static saveNews(id: string, classify: string, filename:string, title: string, catalog: string, content: string, pubtime: string): Promise<boolean> {
        return fetch(this.backAddr + "saveNews", {
            method: "POST", mode: 'cors',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ id, classify, filename, title, catalog, content, pubtime })
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
            else return null;
        });
    }
    public static loadNews(classify: string, filename: string): Promise<boolean> {
        return fetch(this.backAddr + "loadNews", {
            method: "POST", mode: 'cors',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ classify, filename })
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
            else return null;
        });
    }
    public static saveAgroAdvice(content: string): Promise<boolean> {
        return fetch(this.backAddr + "saveAgroAdvice", {
            method: "POST", mode: 'cors',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ content })
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
            else return null;
        });
    }
    public static getAgroAdvice() {
        return fetch(this.backAddr + 'getAgroAdvice').then(resp => {
            if (resp.status == 200) {
                return resp.json()
            }
            else return null;
        });
    }


    
    public static GetAllContacts(keyword: string): Promise<iContactObj[]> {
        return fetch(this.backAddr + 'GetAllContacts/' + (keyword == "" ? "-" : keyword)).then(resp => {
            if (resp.status == 200) {
                return resp.json().then((result: iContactObj[]) => {
                    for (var r of result)
                        r.addtime = new Date(r.addtime.toString());
                    return result;
                });
            }
            else return null;
        });
    }

    public static GetContactById(id: string): Promise<iContactObj> {
        return fetch(this.backAddr + 'GetContactById/' + id).then(resp => {
            if (resp.status == 200) {
                return resp.json().then((result: iContactObj) => {
                    result.addtime = new Date(result.addtime.toString());
                    return result;
                });
            }
            else return null;
        });
    }

    public static DelContact(id: string): Promise<number> {
        return fetch(this.backAddr + "DelContact/" + id).then(resp => {
            if (resp.status == 200)
                return resp.json();
            else return null;
        });
    }

    public static saveContact(obj: any): Promise<boolean> {
        return fetch(this.backAddr + "saveContact", {
            method: "POST", mode: 'cors',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(obj)
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
            else return null;
        });
    }
    
}

