import { Component, createRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Modal } from "bootstrap";
import backendMethod from './API/backend';
import "./css/index.css";
import Enumerable from 'linq';

export default class argoContacts extends Component {
    private modalDiv = createRef<HTMLDivElement>();
    private txtKeyword = createRef<HTMLInputElement>();

    private txtID = createRef<HTMLInputElement>();
    private txtAddTime = createRef<HTMLInputElement>();
    private txtUnit = createRef<HTMLInputElement>();
    private txtAddress = createRef<HTMLInputElement>();
    private txtContact = createRef<HTMLInputElement>();
    private txtTele = createRef<HTMLInputElement>();
    private txtWX = createRef<HTMLInputElement>();
    private txtScope = createRef<HTMLTextAreaElement>();
    private txtNeeds = createRef<HTMLTextAreaElement>();
    public componentDidMount(): void {
        this.showContactList();
        this.modalDiv.current.addEventListener('hidden.bs.modal', evt => {
            document.querySelectorAll(".modal-backdrop.show").forEach(e => { e.remove() });
        });
    }

    private showContactList() {
        backendMethod.GetAllContacts(this.txtKeyword.current.value).then(objs => {
            var tbody = document.querySelector(".contact-table") as HTMLElement;
            while (tbody.lastChild) tbody.removeChild(tbody.lastChild);

            if (objs.length) {

                for (var obj of objs) {
                    var row = tbody.appendElement("tr", { cursor: "pointer" });
                    var td = row.appendTd();
                    td.innerHTML = obj.unit + "<br/>" + obj.address;

                    td = row.appendTd();
                    td.innerHTML = obj.contact_name + "<br/>" + obj.contact_tele;

                    td = row.appendTd();
                    var scope = obj.business_scope
                    while (scope.indexOf('\n') > 0) scope = scope.replace('\n','<br/>');
                    td.innerHTML = scope;

                    td = row.appendTd();
                    var needs = obj.meteorology_needs
                    while (needs.indexOf('\n') > 0) needs = needs.replace('\n', '<br/>');
                    td.innerHTML = needs;

                    td = row.appendTd();
                    var viewbtn = td.appendButton("查看详细信息", { margin: "0 5px" });
                    var editor = td.appendButton("编辑", { margin: "0 5px" });
                    var delbtn = td.appendButton("删除", { margin: "0 5px" });
                    viewbtn.className = editor.className = delbtn.className = "btn btn-primary";
                    ((a) => {
                        viewbtn.onclick = () => {
                            this.addNew(obj)

                            var btnSave = document.getElementById("btnSave");
                            btnSave.style.display="none"
                            var btnClose = document.getElementById("btnClose");
                            btnClose.style.display = ""

                            new Modal(this.modalDiv.current).show();
                        }
                        editor.onclick = () => {
                            this.addNew(obj)

                            var btnSave = document.getElementById("btnSave");
                            btnSave.style.display = ""
                            var btnClose = document.getElementById("btnClose");
                            btnClose.style.display = ""

                            new Modal(this.modalDiv.current).show();
                        }
                        delbtn.onclick = () => {
                            if (window.confirm("确定删除联系人:" + obj.contact_name + "？")) {
                                backendMethod.delArticle(a.id).then(delcount => {
                                    alert("成功删除记录条数" + delcount);
                                    if (delcount) this.showContactList();
                                });
                            }
                        }
                    })(obj);


                }
            }
        });

    }

    private addNew(obj: iContactObj = null) {
        var gender = "1"
        if (obj == null) {

            this.txtID.current.value = "";
            this.txtAddTime.current.value = "";
            this.txtUnit.current.value = "";
            this.txtAddress.current.value = "";
            this.txtContact.current.value = "";
            this.txtTele.current.value = "";
            this.txtWX.current.value = "";
            this.txtScope.current.value = "";
            this.txtNeeds.current.value = "";
        }
        else {
            this.txtID.current.value = obj.id;
            this.txtAddTime.current.value = obj.addtime.toStr("yyyy-MM-dd HH:mm:ss");
            this.txtUnit.current.value = obj.unit;
            this.txtAddress.current.value = obj.address;
            this.txtContact.current.value = obj.contact_name;
            this.txtTele.current.value = obj.contact_tele;
            this.txtWX.current.value = obj.wx_id;
            this.txtScope.current.value = obj.business_scope;
            this.txtNeeds.current.value = obj.meteorology_needs;
            gender = obj.gender + "";
        }
        for (var radio of document.getElementsByName("gender") as NodeListOf<HTMLInputElement>) {
            if (radio.value == gender) {
                radio.checked = true;
                break;
            }
        }

        new Modal(this.modalDiv.current).show();

    }

    private save() {
        var id = this.txtID.current.value;
        var addtime = this.txtAddTime.current.value;
        if (addtime == "") addtime = new Date().toStr("yyyy-MM-dd HH:mm:ss")
        var unit = this.txtUnit.current.value;
        var address = this.txtAddress.current.value;
        var contact_name = this.txtContact.current.value;
        var contact_tele = this.txtTele.current.value;
        var wx_id = this.txtWX.current.value;
        var business_scope = this.txtScope.current.value;
        var meteorology_needs = this.txtNeeds.current.value;

        var gender = 1;
        for (var radio of document.getElementsByName("gender") as NodeListOf<HTMLInputElement>) {
            if (radio.checked) {
                gender = +radio.value;
                break;
            }
        }

        if (!unit) {
            alert("请输入单位"); return;
        }
        if (!contact_name) {
            alert("请输入联系人"); return;
        }
        if (!contact_tele) {
            alert("请输入联系方式"); return;
        }
        if (!business_scope) {
            alert("请输入企业生产经营范围"); return;
        }
        if (!meteorology_needs) {
            alert("请输入气象服务需求"); return;
        }
        //unit ='__ORIG_VAL__.'
        var obj = { id, unit, address, contact_name, contact_tele, wx_id, business_scope, meteorology_needs, addtime, gender };

        backendMethod.saveContact(obj).then(t => {
            if (t) {
                alert("保存成功!");
                if (id == "") {
                    this.txtUnit.current.value = "";
                    this.txtAddress.current.value = "";
                    this.txtContact.current.value = "";
                    this.txtTele.current.value = "";
                    this.txtWX.current.value = "";
                    this.txtScope.current.value = "";
                    this.txtNeeds.current.value = "";
                }
                this.showContactList();

            } else
                alert("保存失败!");
        });


    }

    public render() {
        return <>
            <div style={{ padding: "10px" }}>
                搜索：
                <input type='text' style={{ margin: "0 10px", padding: "0 5px", width: 300 }} ref={this.txtKeyword} />
                <button type="button" style={{ margin: "0 10px", padding: "0 5px" }} className="btn btn-primary" onClick={this.showContactList.bind(this)}>搜&nbsp;索</button>
                <button type="button" style={{ margin: "0 10px", padding: "0 5px" }} className="btn btn-primary" onClick={this.addNew.bind(this, null)}>新增联系人</button>
            </div>
            <div style={{ margin: 10 }}>
                <table className="contact-table">
                    <thead>
                        <tr>
                            <th>
                                单位
                            </th>
                            <th>
                                联系人
                            </th>
                            <th>
                                企业生产经营范围
                            </th>
                            <th>
                                气象服务需求
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                西丽果场<br />
                                南山区西丽湖畔西丽果场
                            </td>
                            <td>
                                李珂<br />
                                86-0755-26626991


                            </td>
                            <td>
                                荔枝、龙眼
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                大鹏农科院<br />
                                龙岗区大鹏街道
                            </td>
                            <td>
                                谢工<br />
                                19918839533

                                18320666566
                            </td>
                            <td>

                            </td>
                            <td>
                                风温雨等气象数据分享
                            </td>
                        </tr>
                        <tr>
                            <td>
                                光明农场
                            </td>
                            <td>
                                0755-88831038
                            </td>
                            <td>
                                农业种植、畜牧业、水产养殖、食用农产品销售、农业休闲旅游等
                            </td>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                福田农场
                            </td>
                            <td>
                                黄晨聪<br />
                                0755-88128586
                            </td>
                            <td>
                                以生态农业为主，注重绿色有机种植
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳大学生态农业科技园
                            </td>
                            <td>
                                0755-86713951
                            </td>
                            <td>
                                种植水果、蔬菜和草本植物等
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市双晖农业科技有限公司
                            </td>
                            <td>
                                叶惠军<br />
                                0755-29427558
                            </td>
                            <td>
                                叶菜类蔬菜作物-生菜 、甘蓝类蔬菜
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市源兴果品股份有限公司
                            </td>
                            <td>
                                卢金炮<br />
                                0755-25840333
                            </td>
                            <td>
                                花卉   、水果   、饲料   、种子   、农副产品加工
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市华盛实业股份有限公司
                            </td>
                            <td>
                                黄子应<br />
                                0755-82238700
                            </td>
                            <td>
                                花卉、水果、蔬菜、纺织品遮篷、纺织品上光皂、纺织品、石斛、动物食用糠料、纺织品织造、水果类等
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市果菜贸易有限公司
                            </td>
                            <td>
                                王欣<br />
                                0755-82380555
                            </td>
                            <td>
                                果蔬农业、食品副食品加工业、种子种苗农药化肥等的生产和经营
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市绿田农业开发有限公司
                            </td>
                            <td>方立群<br />
                                0755-25853638
                            </td>
                            <td>
                                果蔬类产品(西红柿   、红萝卜   、苹果   、橙等水果)   、水果礼篮等
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市旺泰佳农业开发有限公司
                            </td>
                            <td>蔡楚葵<br />
                                0755-25850332
                            </td>
                            <td>
                                蔬菜,水果,五谷杂粮,农场旅游
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市寰通农产品有限公司
                            </td>
                            <td>钟振华<br />
                                0755-25566202
                            </td>
                            <td>
                                花卉   、水果   、饲料   、种子   、农副产品加工
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                        <tr>
                            <td>
                                深圳市田地农产品有限公司
                            </td>
                            <td>余祥辉<br />
                                13825222002
                            </td>
                            <td>
                                肉类   、米   、蔬菜   、调味品   、劳保用品   、禽肉
                            </td>
                            <td>
                                高温预警<br />寒潮霜冻预警<br />暴雨预警<br />台风预警
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="modal" ref={this.modalDiv}  >
                <div className="modal-dialog">
                    <div className="modal-content" style={{ minHeight: window.innerHeight - 100, marginTop: 30 }}>
                        <div className="modal-header" style={{ padding: 5, backgroundColor: "#0d6efd" }}>
                            <span style={{ width: "100%", textAlign: "center", color: "#fff", fontSize: 16, fontWeight: "bold" }}>编辑联系人信息</span>
                        </div>
                        <div className="modal-body" style={{ paddingBottom: 10 }}>
                            <div>
                                <table className='table table-bordered table-sm contact-table-add' style={{ fontSize: 13 }}>
                                    <tbody>
                                        <tr><th>单&nbsp;位</th><td><input type='text' ref={this.txtUnit} /></td></tr>
                                        <tr><th>地&nbsp;址</th><td><input type='text' ref={this.txtAddress} /></td></tr>
                                        <tr><th>联系人</th><td><input type='text' ref={this.txtContact} /></td></tr>
                                        <tr><th>联系电话</th><td><input type='text' ref={this.txtTele} /></td></tr>
                                        <tr><th>微信ID</th><td><input type='text' ref={this.txtWX} /></td></tr>
                                        <tr><th>联系人性别</th>
                                            <td>
                                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                    <label style={{ margin: 6 }}><input type='radio' name="gender" value={1} /> <i className="bi bi-gender-male"></i> 男</label>
                                                    <label style={{ margin: 6 }}><input type='radio' name="gender" value={0} /> <i className="bi bi-gender-female"></i> 女</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: 98 }}>企业生产经营范围</th>
                                            <td><textarea ref={this.txtScope}></textarea></td>
                                        </tr>
                                        <tr>
                                            <th>气象服务需求</th>
                                            <td><textarea ref={this.txtNeeds}></textarea></td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}>
                                                <input type='hidden' ref={this.txtID} />
                                                <input type='hidden' ref={this.txtAddTime} />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer" style={{ padding: 2 }}>
                            <div className="btn-group" role="group" style={{ width: "100%" }}>
                                <button type="button" className="btn btn-primary btn-sm"
                                    style={{ fontWeight: "bold", marginRight: 5 }} id="btnSave"
                                    onClick={this.save.bind(this)}>保&nbsp;存</button>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginLeft: 5 }}
                                    id="btnClose"
                                    data-bs-dismiss="modal">关&nbsp;闭</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}